import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'

const Index = ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sls-intro-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
        />

        <Hero
          class="home-110"
          foreimage={post.frontmatter.image.childImageSharp.gatsbyImageData}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="home"
        />
      </div>

      {post.html &&
        <main id="main">
          <section>
            <div className="container">
              <div className="font-w-400 text-medium pt-2" dangerouslySetInnerHTML={{__html: post.html}}></div>
            </div>
          </section>
        </main>
      }
    </Layout>
  )
}
export default Index 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        image: foreimage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 540, quality: 90) 
          }
        }
        alttext        
      }
    }
  }
`