import React from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'
import Tilt from 'react-tilt'

class HeroTwo extends React.Component {
  handleClick(value) {
    typeof window !== 'undefined' && document.querySelector(value).scrollIntoView({
      behavior: 'smooth'
    })
  }
  render() {
    return (
      <section className={this.props.class}>
        <div className="view">
          <div className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center align-items-center gradient' } >
            <div className="container">
              <div className="row">
                {this.props.type === 'home' || this.props.type === '404' ? (
                  <>
                    <div className="col-lg-6 my-auto text-lg-left text-center" >
                      <h1
                        className="font-alt font-w-700 title-xs-extra-large title-extra-large-1 mb-5 mb-xl-5"
                        dangerouslySetInnerHTML={{ __html: this.props.title }}
                      />
                      <h2
                        className="font-alt font-w-400 mb-5 title-xs-small title-small letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: this.props.subtitle, }}
                      />
                        {this.props.ctaslug && (
                            <button to={this.props.ctaslug} offset={-150} className="nav-link btn btn-hero-color mr-0 mt-xl-2 btn-w-245 hero-btn1"
                              onClick={() => this.handleClick(this.props.ctaslug) }
                            >
                              <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                              {this.props.ctatext ? this.props.ctatext : 'Explore'}
                            </button>
                          )}
                          {this.props.ctaslug2 && ( 
                            <>
                            {this.props.ctaslug2.substring(0,4) === 'http' ? 
                            <a href={this.props.ctaslug2} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2">
                              <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                              {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                            </a>
                            :
                            <Link to={this.props.ctaslug2} className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2">
                              <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                              {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                            </Link>
                            }
                            </>
                          )}
                    </div>
                  </>
                ) : (
                  <div className="text-center col-md-12 mt-xl-2">
                    <h1
                      className="font-alt font-w-700 text-yellow title-xs-extra-large title-extra-large-1 mb-5 mt-sm-5 mb-xl-4"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />
                    {this.props.subtitle != '' && this.props.subtitle != null && (
                      <h2
                        className="text-white font-alt font-w-400 mb-4 title-xs-small title-small letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: this.props.subtitle, }}
                      />
                    )}

                    {this.props.ctaslug != '' && this.props.ctaslug != null && (
                      <Link to={this.props.ctaslug} className="nav-link btn btn-mdb-color mr-0 mt-xl-3" >
                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                        {this.props.ctatext != '' && this.props.ctatext != null ? this.props.ctatext : 'Read'}
                      </Link>
                    )}
                  </div>
                )}

                {this.props.foreimage != '' && this.props.foreimage != null && (
                  <div className="col-lg-6 mt-5 mt-lg-0 pl-lg-5 py-5">
                    <div className="d-none d-lg-block">
                      <Tilt className="Tilt" options={{ max: 25 }} style={{ height: 500, width: 500 }} >
                        <img src={this.props.foreimage.images.fallback.src} loading="lazy" alt={this.props.alt} />
                      </Tilt>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div id="scrollto">&nbsp;</div>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroTwo